.item,
.header,
.button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding-left: 10px;
}

.tabs-container {
    display: flex;
    height: 48px;
    border-bottom: 1px solid black;
}

.tabs-button {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    flex-direction: column;
    align-items: center;
}

.tabs-button.selected {

}

.header {
    position: sticky;
    top: 0;
}

.isOpen {
    transform: rotate(180deg);
}

.button {
    height: 50px;
}
